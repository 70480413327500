import { Col, Row, Typography } from 'antd';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

import { findImageByBase } from '@/utils';

const PhotoComposition: React.FC<{
  data: { title: string; text: string; photoBase: string }[];
}> = ({ data }) => {
  const {
    allFile: { nodes: allPhotos },
  } = useStaticQuery(graphql`
    {
      allFile(filter: { sourceInstanceName: { eq: "photos" } }) {
        nodes {
          id
          base
          childImageSharp {
            gatsbyImageData(width: 624, height: 624)
          }
        }
      }
    }
  `);

  return (
    <>
      {data.map((i, idx) => (
        <React.Fragment key={idx}>
          <Row
            key={idx}
            style={{
              flexDirection: idx % 2 === 1 ? 'row' : 'row-reverse',
            }}
          >
            <Col sm={24} md={12}>
              <div
                className="prose prose-xl"
                style={{
                  padding:
                    idx % 2 === 1 ? '2.5rem 3rem 2rem 0' : '2.5rem 0 2rem 3rem',
                  float: idx % 2 === 1 ? 'right' : 'left',
                }}
              >
                <Typography.Paragraph className="text-4xl">
                  {i.title}
                </Typography.Paragraph>
                <Typography.Paragraph className="">
                  {i.text}
                </Typography.Paragraph>
              </div>
            </Col>
            <Col sm={0} md={12}>
              <GatsbyImage
                image={findImageByBase(allPhotos, i.photoBase) as any}
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                }}
                imgStyle={{
                  objectFit: 'cover',
                }}
                alt={i.photoBase}
              />
            </Col>
          </Row>
        </React.Fragment>
      ))}
    </>
  );
};

export default PhotoComposition;
