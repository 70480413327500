import React from 'react';
import { Helmet } from 'react-helmet';

import { useSiteMetadata } from '@/hooks/useSiteMetadata';
const OrganizationSchema: React.FC = () => {
  const { title: brandName, siteUrl, logoUrl } = useSiteMetadata();
  const organizationLdJson = JSON.stringify({
    '@context': 'http://schema.org',
    '@type': 'Organization',
    name: brandName,
    alternateName: 'Rasept',
    url: siteUrl,
    logo: `${siteUrl}${logoUrl}`,
    description:
      'Единственная на российском рынке производственная компания, выпускающая антисептики для рук с использованием экологичного натурального антисептического вещества – тимол. Компания производит косметическую продукцию, в основе которой используются передовые косметические составы и компоненты, одобренные всемирной организацией здравоохранения.',
    address: {
      '@type': 'PostalAddress',
      streetAddress: 'пр. Кирова 24, стр. 2',
      addressLocality: 'Самара',
      addressRegion: 'Самарская обл.',
      postalCode: '443022',
      addressCountry: 'RU',
    },
    contactPoint: [
      {
        '@type': 'ContactPoint',
        telephone: '+7 (846) 922-88-33',
        email: 'mail@rasept.com',
        contactType: 'Информация',
      },
    ],
    sameAs: [
      'https://vk.com/rasept',
      'https://www.instagram.com/raseptcom/',
      'https://www.facebook.com/RaseptCom/',
      'https://ok.ru/group/58832528343234',
      'https://twitter.com/RaseptCom',
    ],
  });

  return (
    <Helmet>
      <script type="application/ld+json">{organizationLdJson}</script>
    </Helmet>
  );
};

export default OrganizationSchema;
