import { Card, Col, Row, Typography } from 'antd';
import fitty from 'fitty';
import { graphql, PageProps } from 'gatsby';
import { getSrc } from 'gatsby-plugin-image';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import Container from '@/components/Container';
import Layout from '@/components/Layout';
import PhotoComposition from '@/components/PhotoComposition';
import SEO from '@/components/SEO';
import { useSiteMetadata } from '@/hooks/useSiteMetadata';
import OrganizationSchema from '@/schema/OrganizationSchema';

import * as styles from './index.module.css';

const aboutCompany = `«НПО Расепт» — российский производитель косметики новой формации и единственная на российском рынке производственная компания, выпускающая антисептики для рук с использованием экологичного натурального антисептического вещества – тимол, разрешенного к применению детям.
Так же компания производит: коллагеновые гели для умывания, средства для снятия макияжа, средства по уходу за кожей лица, крем-мыло для интимной гигиены, детские шампуни, шампуни, антицеллюлитные средства.
Данная продукция выверена с учётом требований массового потребителя, в основе которых используются передовые косметические составы и компоненты, одобренные всемирной организацией здравоохранения.`;

const companyAdvantages = [
  {
    title: 'Актуальные технологии производства',
    text: `Представители компании посещают выставки фармацевтического и косметического сырья, где знакомятся с передовыми разработками в области косметологии и перенимают опыт лидеров косметической индустрии. На производстве трудится высококвалифицированный персонал и это даёт возможность в кратчайшие сроки внедрять в производство новинки продукции.`,
  },
  {
    title: 'Сырьевая ориентация',
    text: `В разработке рецептур основное внимание уделяется сырьевой базе. Лаборатория «НПО Расепт» при создании продуктов опирается на подбор компонентов, которые 100% раскрывают назначение продукта. Используя опыт и накопленные знания в совместимости ингредиентов, создаются косметические продукты высокого качества с высоким содержанием активных компонентов.`,
  },
  {
    title: 'Международная специализация и кооперация',
    text: `Для сохранения низких цен на продукцию «НПО Расепт» в полной мере использует международную кооперацию и специализацию — является участником Внешне-Экономической Деятельности. Мы поддерживаем связи и производим закупки сырья, материалов и необходимого оборудования непосредственно у прямых  производителей в Индии, Китае, Польше и Италии. Импорт без посредников снижает издержки на производство продукции и гарантирует качественное сырьё.`,
  },
  {
    title: 'Компьютеризация',
    text: `«НПО Расепт» использует автоматическое оборудование полного цикла. 100% автоматизация и компьютеризация  разрешают управлять производством без вмешательства «человеческого фактора». Общение с партнёрами выстроено на программных продуктах и подтверждено электронной подписью, что расширяет контроль и  фиксацию в достигнутых при сотрудничестве  решениях и обязательствах. Электронный документооборот, который мы используем, служит гарантом своевременных и безошибочных взаиморасчётов с контрагентами.`,
  },
  {
    title: 'Сертификация косметической продукции',
    text: `Ключевое место в производственном цикле занимают испытания и сертификация продукции «НПО Расепт». На протяжении четырёх лет мы сотрудничаем с ФГБУ «Государственным научным центром дерматовенерологии и косметологии» Министерства здравоохранения РФ, где продукция проходит лабораторные испытания и получая протокол испытаний — допускается к производству. Компания выпускает антисептические и антибактериальные средства, антицеллюлитную серию продукции, средства по уходу и очищению кожи лица, составы которых удовлетворяют требования массового потребителя. В составах используются компоненты, одобренные всемирной организацией здравоохранения и разрешённые министерством здравоохранения РФ.`,
  },
];

const mainContent = [
  {
    title: 'Мы производим до 360 000 единиц продукции в месяц',
    text: `Производство предприятия динамично разрабатывает, развивает и выводит на рынок востребованные косметические продукты. Продукция отмечена высоким качеством и доступной ценой для массового потребителя.`,
  },
  {
    title: 'Основы производственной деятельности «НПО Расепт»',
    content: [
      {
        description: `Лабораторные возможности компании позволяют разрабатывать рецептуры
      для любой косметической продукции с использованием передовых и
      качественных компонентов. Система контроля качества на предприятии
      начинается с отбора и проверки входящих в состав ингредиентов.
      Технологический процесс контролируется на всех этапах производства,
      включая хранение и транспортировку готовой косметической продукции
      непосредственно до заказчика.`,
        image: '/icons/flask.svg',
        title: 'Лаборатория',
      },
      {
        description: `Наше производство косметической продукции оснащено автоматическим
      оборудованием полного цикла по GMP стандарту. Специалисты нашей
      компании прошли профильное обучение и допущены к рабочему процессу
      согласно аттестации. Санитарная обработка оборудования и
      производственных помещений производится ежедневно, согласно ГОСТу.
      Санобработка производится с использованием мягких очистителей для
      пищевой и косметической промышленности.`,
        image: '/icons/factory.svg',
        title: 'Производство',
      },
      {
        description: `Компания производит прямые закупки у производителей косметики Индии
      и Китая. Европейское сырьё с соответствующими сертификатами качество
      закупается в России у официальных партнёров заводов-производителей.
      По материалам упаковки для косметической продукции компания широко
      сотрудничает с ведущими российскими производителями. Упаковка
      косметики изготовлена из экологичного сырья и тоже имеет сертификат
      качества.`,
        image: '/icons/earth.svg',
        title: 'Сотрудничество',
      },
    ],
  },
];

const [development, advantages] = mainContent; // TODO , possibilities]

const IndexPage: React.FC<PageProps & { data: any }> = ({
  uri,
  data: { file },
}) => {
  const posterImg = getSrc(file);
  const { siteUrl, title } = useSiteMetadata();
  const webSiteLdJson = JSON.stringify({
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    url: siteUrl,
    name: title,
  });

  useEffect(() => {
    fitty(`.fitty`, { minSize: 38 });
  }, []);

  return (
    <Layout>
      <SEO
        title="Производитель антисептиков и косметики"
        description="Российский производитель безспиртовых антисептических средств для гигиены рук в виде геля и жидкости, косметики по уходу за кожей тела и лица"
        uri={uri}
        image={null}
      />
      <Helmet>
        <meta property="wot-verification" content="c00205102ada92f13c83" />
        <meta name="msvalidate.01" content="11C4FA9A78A0CD20FDC7CC5D6C67661E" />
        <meta
          name="google-site-verification"
          content="k4HVKnINPqDASENcDiSrzV-RG1P7U25j94VY2Rk7kbU"
        />
        <script type="application/ld+json">{webSiteLdJson}</script>
      </Helmet>
      <OrganizationSchema />
      <div className={styles.HeroVideo}>
        <video
          muted
          loop
          autoPlay
          poster={posterImg}
          className="w-full object-cover"
          style={{ height: 'calc(90vh - 64px)' }}
        >
          <source src="/video/rasept-video.webm" type="video/webm" />
        </video>
        <div className={styles.HeroVideoText}>
          <Container className="px-0">
            <h1 className="fitty" style={{ fontSize: 46 }}>
              Производитель антисептиков и косметики
            </h1>
            <Typography.Title level={3}>
              производим продукцию, заботящуюся о вашем здоровье
            </Typography.Title>
          </Container>
        </div>
      </div>
      <Row>
        <Col flex="auto">
          <section className=" py-12" style={{ backgroundColor: '#fff' }}>
            <Container className="prose prose-xl">
              <Typography.Paragraph className="text-center text-4xl">
                {development.title}
              </Typography.Paragraph>
              <Typography.Paragraph className="">
                {aboutCompany} {development.text}
              </Typography.Paragraph>
            </Container>
          </section>
        </Col>
      </Row>
      <Row>
        <Col flex="auto">
          <Container>
            <div className="text-center mt-12 mb-4">
              <Typography.Text className="text-3xl">
                {advantages.title}
              </Typography.Text>
            </div>
            <Row gutter={32}>
              {advantages.content?.map((i, idx) => (
                <Col flex="auto" sm={24} md={8} key={idx} className="mb-8">
                  <Card
                  // cover={
                  //   <div
                  //     style={{
                  //       width: '100%',
                  //       height: 0,
                  //       paddingBottom: '50%',
                  //       margin: '2rem 0',
                  //     }}
                  //   >
                  //     <img
                  //       alt={i.title}
                  //       src={i.image}
                  //       style={{
                  //         margin: '0 auto',
                  //       }}
                  //     />
                  //   </div>
                  // }
                  >
                    <Card.Meta
                      title={
                        <div className="text-center text-2xl">{i.title}</div>
                      }
                      description={i.description}
                    />
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        </Col>
      </Row>
      <Typography.Paragraph className="text-center text-4xl mt-16">
        Наши производственные принципы
      </Typography.Paragraph>
      <Container>
        <PhotoComposition
          data={companyAdvantages.map((i, idx) => {
            const companyAdvantagesPhotos = [
              '4440227.jpg',
              '3735698.jpg',
              '2763394.jpg',
              '4657256.jpg',
              '5682668.jpg',
            ];

            return {
              ...i,
              photoBase: companyAdvantagesPhotos[idx],
            };
          })}
        />
      </Container>
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPage {
    file(
      sourceInstanceName: { eq: "video" }
      name: { eq: "rasept-video-poster" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(height: 600, quality: 50, formats: [WEBP])
      }
    }
  }
`;
